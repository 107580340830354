<!--
* @description:
* @fileName pageEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-drawer
    ref="drawerRef"
    v-model="dialogFormVisible"
    :title="title"
    :size="size"
    :before-close="handleClose"
    direction="rtl"
    custom-class="demo-drawer"
  >
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="事件名称" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>

      <el-form-item label="事件简称" prop="shortName">
        <el-input v-model="form.shortName" />
      </el-form-item>

      <el-form-item label="详细地址" prop="address">
        <el-input v-model="form.address" />
      </el-form-item>

      <el-form-item label="地址简称" prop="shortAddress">
        <el-input v-model="form.shortAddress" />
      </el-form-item>

      <el-form-item label="事件id" prop="eventId">
        <el-input v-model="form.eventId" placeholder="18位 字母加数字 小写	" />
      </el-form-item>

      <el-form-item label="事件key" prop="eventKey">
        <el-input v-model="form.eventKey" />
      </el-form-item>

      <el-form-item label="事件描述" prop="description">
        <el-input v-model="form.description" />
      </el-form-item>

      <el-form-item label="事件模版" prop="pageId">
        <el-select v-model="form.pageId">
          <template v-for="(item, index) in pageList" :key="index">
            <el-option :label="item.name" :value="item.id"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item label="事件类型" prop="eventTypeId">
        <el-select v-model="form.eventTypeId">
          <template v-for="(item, index) in eventTypeList" :key="index">
            <el-option :label="item.type" :value="item.id"></el-option>
          </template>
        </el-select>
      </el-form-item>

      <el-form-item label="事件时间" prop="date">
        <el-input v-model="form.date" />
      </el-form-item>

      <el-form-item label="模版封面" prop="imgUrl" :label-width="90">
        <el-upload
          class="upload-demo"
          action="#"
          list-type="picture-card"
          :limit="1"
          :file-list="form.imgList"
          :http-request="upLogo"
          :on-error="handleError"
          :on-exceed="handleExceed1"
          :on-preview="handlePreview"
          :on-remove="handleRemove1"
          accept=".png,.jpeg,.jpg"
        >
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            <em>点击上传图片</em>
          </div>
          <!-- <template #tip>
              <div class="el-upload__tip">
                jpg/png files with a size less than 500kb
              </div>
            </template> -->
        </el-upload>

        <!-- <el-image
            :src="form.bannerUrl"
            :fit="'fill'"
            v-else
            style="width: 360px; height: 180px"
          ></el-image> -->
      </el-form-item>

      <el-form-item label="事件负责人" prop="sponsorName">
        <el-input v-model="form.sponsorName" />
      </el-form-item>
      <el-form-item label="负责人电话" prop="sponsorPhone">
        <el-input v-model="form.sponsorPhone" />
      </el-form-item>
    </el-form>
    <div class="demo-drawer__footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="save()">提交</el-button>
    </div>
  </el-drawer>

  <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
    <div>
      <el-image :src="dialogImageUrl" />
    </div>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { upLoadImg } from '@/api/active'
  import {
    saveEventPage,
    editEventPage,
    getPage,
    getEventType,
  } from '@/api/page'
  import { getAllEvent } from '@/api/global'

  export default defineComponent({
    name: 'pageEdit',
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        formRef: null,
        treeRef: null,
        checkMenu: [],
        pageList: [],
        eventTypeList: [],
        form: {
          pageData: '',
          pageType: 'HOME',
          imgList: [],
        },
        queryForm: { name: '', pageNumber: 1, pageSize: 99999 },
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入事件名称' },
          ],
          shortName: [
            { required: true, trigger: 'blur', message: '请输入事件简称' },
          ],
          address: [
            { required: true, trigger: 'blur', message: '请输入详细地址' },
          ],
          shortAddress: [
            { required: true, trigger: 'blur', message: '请输入地址简称' },
          ],
          eventKey: [
            { required: true, trigger: 'blur', message: '请输入事件key' },
          ],
          description: [
            { required: true, trigger: 'blur', message: '请输入事件描述' },
          ],
          date: [
            { required: true, trigger: 'blur', message: '请选择事件事件' },
          ],

          imgUrl: [
            { required: true, trigger: 'change', message: '请选择事件图片' },
          ],
          pageId: [
            { required: true, trigger: 'change', message: '请选择事件模版' },
          ],
          eventTypeId: [
            { required: true, trigger: 'change', message: '请选择事件类型' },
          ],
          eventId: [
            { required: true, trigger: 'change', message: '请选择事件id' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        dialogVisible: false,
        dialogImageUrl: '',
        list: [],
      })

      const showEdit = async (row) => {
        if (!row) {
          state.title = '添加'
        } else {
          state.title = '编辑'

          state.form = Object.assign({}, row)
          state.form.imgList = [{ name: 'logo', url: row.imgUrl }]

          console.log(state.form.imgList)
        }
        state.dialogFormVisible = true
      }
      const close = () => {
        state['formRef'].resetFields()
        state.form = {
          btnRolesCheckedList: [],
        }
        state.dialogFormVisible = false
      }

      const save = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            let msg = ''
            if (state.title == '编辑') {
              let data = await editEventPage(state.form)
              msg = data.msg
            } else {
              let data = await saveEventPage(state.form)
              msg = data.msg
            }
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            close()
          }
        })
      }

      // 上传海报
      const upLogo = async (e) => {
        let upData = new FormData()
        upData.append('file', e.file)
        const { data } = await upLoadImg(upData)
        state.form.imgUrl = data
      }

      const handleError = (err, file) => {
        proxy.$baseMessage(
          `文件[${file.raw.name}]上传失败,文件大小为${_.round(
            file.raw.size / 1024,
            0
          )}KB`,
          'error',
          'vab-hey-message-error'
        )
      }

      const handleExceed1 = (files) => {
        proxy.$baseMessage(
          `当前限制选择1个文件，本次选择了
             ${files.length}
             个文件`,
          'error',
          'vab-hey-message-error'
        )
      }

      const handleRemove1 = (file, fileList) => {
        state.form.imgUrl = ''
      }

      const handlePreview = (file) => {
        state.dialogImageUrl = file.url
        console.log(file.url)
        state.dialogVisible = true
      }

      // 获取所有事件
      // const handleGetAllEvent = async () => {
      //   const { data } = await getAllEvent()
      //   state.eventList = data
      // }

      const fetchData = async () => {
        const { data } = await getPage(state.queryForm)
        state.pageList = data.list
        const { data: eventTypeData } = await getEventType(state.queryForm)
        state.eventTypeList = eventTypeData.data
      }

      onMounted(() => {
        fetchData()
      })

      return {
        ...toRefs(state),
        showEdit,
        close,
        save,
        upLogo,
        handleError,
        handleExceed1,
        handleRemove1,
        handlePreview,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }

  .demo-drawer__content {
    height: 85vh;
    overflow: auto;
  }
  .demo-drawer__footer {
    position: fixed;
    right: 20px;
    bottom: 10px;
  }
</style>
